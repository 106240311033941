import React from "react"
import "./src/styles/global.css"

// export const wrapPageElement = ({ element, props }) => {
//   return (
//     {element}
//   )
// }

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    })
  }
}
